import './App.css';
import Country from './components/Country';


function App() {
  return (
    <div className="App">
      <Country/>
    </div>
  );
}

export default App;
